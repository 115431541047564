<template>
	<main>
		<div class="section s5">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="list">
						<div class="item" v-for="(item,index) in video" :key="index">
							<div class="video-box">
								<a-image/>
								<div class="video" :style="{backgroundImage:'url('+cover(item)+')'}">
									<div class="mark">
										<div class="play" @click="playVideo(item)"></div>
									</div>
								</div>
							</div>
							<div class="info">
								<div class="element-more title">{{ item.title }}</div>
							</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
		<components-video ref="componentsVideo"/>
	</main>
</template>
<script>
import ComponentsVideo from "./s5/Video"
export default {
	components:{ComponentsVideo},
	props:{
		video:{
			type:Array,
			default:[]
		}
	},
	data(){
		return {
			swiper:"",
			show_more:false,
		}
	},
	methods:{
		cover(item){
			return item.cover ? item.cover : item.video ? item.video+"?x-oss-process=video/snapshot,t_3000" : "";
		},
		playVideo(item){
			const video = item.source;
			this.$refs['componentsVideo'].init({
				source:video,
			})
		},
	}
}
</script>
<style lang="scss" scoped>
.s5{padding-top: 100px;
	.section-wrap{text-align: center;
		.title-box{display: inline-block; width: 537px;height:134px; background: url("~@/assets/image/2024/t4.png") no-repeat top center;background-size: contain;}
		.content-box{margin-top: 60px;
			.list{display: flex;flex-wrap: wrap;
				.item{width: calc((100% - 22px*2)/3);margin-right: 22px;margin-bottom: 22px;
					.video-box{position: relative;
						.video{position: absolute;top:0;left:0;width: 100%;height:100%; background-repeat: no-repeat;background-position: center center;background-size: cover;
							.mark{position: absolute;top:0;left:0;width: 100%;height:100%;display: flex;align-items: center;justify-content: center;background: rgba(0,0,0,.3);
								.play{width: 46px;height:46px;background: url("~@/assets/image/15.png") no-repeat center center;background-size: contain;cursor: pointer;}
							}
						}
					}
					.info{padding: 20px;font-size: 18px;font-weight: bold;color:#fff;}
				}
				.item:nth-child(3n){margin-right: 0;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.s5{padding-top: middle(100px);
		.section-wrap{
			.title-box{width:middle( 537px);height:middle(134px);}
			.content-box{margin-top:middle( 60px);
				.list{
					.item{width: calc((100% - 22px*2*.82)/3);margin-right: middle(22px);margin-bottom: middle(22px);
						.video-box{
							.video{
								.mark{
									.play{width: middle(46px);height:middle(46px);}
								}
							}
						}
						.info{padding:middle(20px);font-size: middle(18px);}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s5{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:67px;}
			.content-box{margin-top:12px;
				.list{display: block;
					.item{width:100%;margin-right: 0;margin-bottom: 16px;
						.video-box{
							.video{
								.mark{
									.play{width:46px;height:46px;}
								}
							}
						}
						.info{padding:10px 16px;font-size: 16px;}
					}
				}
			}
		}
	}
}
</style>
