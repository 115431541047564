<template>
	<main>
		<div class="section s6">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="list">
						<a-link :to="item.source" class="item" v-for="(item,index) in file" :key="index">
							<div class="icon"></div>
							<div class="info">
								<div class="title">{{item.title}}</div>
								<div class="btn">下载</div>
							</div>
						</a-link>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {
	props:{
		file: {
			type:Array,
			default:()=>{
				return []
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.s6{padding-top: 60px;position: relative;
	.section-wrap{text-align: center;position: relative;z-index: 2;
		.title-box{display: inline-block; width: 537px;height:134px; background: url("~@/assets/image/2024/t6.png") no-repeat top center;background-size: contain;}
		.content-box{margin-top: 60px;text-align: left;
			.list{display: flex;flex-wrap: wrap;
				.item{ width: calc((100% - 130px) / 2);margin-right: 18px;margin-left: 39px; margin-bottom: 18px;display: flex;padding:20px;background: #e5effe;border-radius: 8px;box-shadow: 0 7px 14px 0 rgba(0,0,0,0.02);align-items: center;
					.icon{width: 42px;height:43px;background: url("~@/assets/image/2024/download.png") no-repeat left center;background-size: contain;}
					.info{flex: 1;display: flex;align-items: center;
						.title{flex: 1; margin-left: 20px;font-size: 18px;font-weight: bold;color:#111;white-space: pre-line;}
						.btn{width: 122px;height:40px;border-radius: 20px;text-align: center;border: 1px solid #1a3692;font-size: 18px;color:#1a3692;line-height: 40px;margin-left: 15px;}
					}

				}
				.item:nth-child(2n){margin-right: 0;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.s6{padding-top: middle(60px);
		.section-wrap{
			.title-box{width: middle(537px);height:middle(134px); }
			.content-box{margin-top: middle(60px);
				.list{
					.item{ width: calc((100% - 130px * .82) / 2);margin-right: middle(18px);margin-left: middle(39px); margin-bottom: middle(18px);padding:middle(20px);border-radius: middle(8px);
						.icon{width: middle(42px);height:middle(43px);}
						.info{
							.title{margin-left: middle(20px);font-size: middle(18px);}
							.btn{width: middle(122px);height:middle(40px);border-radius: middle(20px);font-size: middle(18px);line-height: middle(40px);margin-left: middle(15px);}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s6{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:67px;}
			.content-box{margin-top: 15px;
				.list{
					.item{width:calc((100% - 15px)/2);margin-bottom: 10px;align-items: flex-start;margin-right: 10px;padding: 10px;position: relative;margin-left: 0;
						.icon{width: 21px;height:22px;position: absolute;bottom:10px;right:10px;opacity: .5;}
						.info{flex-direction: column;align-items: flex-start;margin-left:0;
							.title{font-size: 13px;margin-left: 0;}
							.btn{margin-top: 10px;width: 50px;height:20px;border-radius: 16px;line-height: 20px;margin-left: 0;font-size: 12px;}
						}
					}
					.item:nth-child(2n){margin-right: 0;}
				}
			}
		}
	}
}
</style>
