<template>
	<main>
		<div class="section s3">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="speaker">
						<div class="swiper" id="s3-swiper">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="(item,index) in speaker" :key="index">
									<div class="user">
										<div class="avatar">
											<img :src="item.avatar" alt="">
										</div>
										<div class="info">
											<div class="element-more username">{{ item.username }}</div>
											<div class="element-more position">{{ item.position }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="swiper-pagination"></div>
						</div>
						<div class="swiper-prev" @click="swiperPrev"></div>
						<div class="swiper-next" @click="swiperNext"></div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {
	props:{
		speaker:{
			type:Array,
			default:()=>{
				return []
			}
		}
	},
	data(){
		return {
			swiper:""
		}
	},
	mounted() {},
	methods:{
		swiperNext(){
			this.swiper.slideNext();
		},
		swiperPrev(){
			this.swiper.slidePrev();
		},
		swiperInit(){
			this.swiper = new Swiper('#s3-swiper',{
				slidesPerView:5,
				spaceBetween: 16,
				centeredSlides:false,
				loop:false,
				autoplay:{
					delay:30000000,
					disableOnInteraction:false,
				},
				observer:true,
				observeParents:true,
				observeSlideChildren:true,
				resizeObserver:true,
				watchOverflow:true,
				pagination: {
					el: "#s3-swiper .swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					300: {
						slidesPerView:2,
						spaceBetween: 0,
						centeredSlides:false,
					},
					992: {
						slidesPerView:5,
						spaceBetween: 16,
						centeredSlides:false,
					}
				}
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.s3{padding-top: 60px;position: relative;
	.section-wrap{text-align: center;
		.title-box{display: inline-block;width: 537px;height:134px;  background: url("~@/assets/image/2024/t2.png") no-repeat top center;background-size: contain;}
		.content-box{margin-top:30px;
			.speaker{position: relative;
				.swiper{
					.swiper-wrapper{
						.swiper-slide{transition:all .3s ease;
							.avatar{
								img{width: 100%;height:220px;display: block; object-fit: contain;object-position: bottom left;}
							}
							.info{text-align: left;transition:all .3s ease;color:#1a3692;position: relative;z-index: 3;
								.username{font-size: 18px;font-weight: bold;transition:all .3s ease;}
								.position{margin-top: 10px;font-size: 15px;-webkit-line-clamp: 10;white-space: pre-line;color:#111;}
							}
						}
					}
					.swiper-pagination{display: none;}
				}
				.swiper-prev{display:none;position: absolute;left:0;top:50%;width: 69px;height:69px;z-index: 2; border-radius: 50%;background:rgba(255,255,255,.4) url("~@/assets/image/17.png") no-repeat center center;background-size: 21px;transform: translate(-100px,-50%);cursor: pointer;}
				.swiper-prev:hover{background-image: url("~@/assets/image/4.png");transition: all .3s ease;}
				.swiper-next{display:none;position: absolute;right:0;top:50%;width: 69px;height:69px;z-index: 2; border-radius: 50%;background:rgba(255,255,255,.4) url("~@/assets/image/16.png") no-repeat center center;background-size: 21px;transform: translate(100px,-50%);cursor: pointer;}
				.swiper-next:hover{background-image: url("~@/assets/image/3.png");transition: all .3s ease;}
			}
		}
	}
}

@media screen and (max-width: 1230px) {
	.s3{padding-top: middle(60px);
		.section-wrap{
			.title-box{width: middle(537px);height:middle(134px); padding: middle(60px) 0 middle(5px);}
			.content-box{margin-top:middle(30px);
				.speaker{
					.swiper{
						.swiper-wrapper{
							.swiper-slide{
								.avatar{
									img{height:middle(220px)}
								}
								.info{transform: translateY(-10px * .82);
									.username{font-size: middle(18px);}
									.position{margin-top: middle(10px);font-size: middle(15px);}
								}
							}
						}
					}
					.swiper-prev{width: middle(69px);height:middle(69px);background-size: middle(21px);}
					.swiper-next{width: middle(69px);height:middle(69px);background-size: middle(21px);}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s3{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:67px;}
			.content-box{margin-top:0;
				.speaker{
					.swiper{height:auto;padding-top: 0;padding-bottom: 30px;
						.swiper-wrapper{
							.swiper-slide{
								.avatar{height:middle(220px);
									img{position: relative;bottom:auto;right:auto;}
								}
								.info{transform: translateY(-22px * .82);
									.username{font-size: middle(18px);}
									.position{margin-top: middle(10px);font-size: middle(16px);}
								}
							}
						}
						.swiper-pagination{display: block;
							::v-deep{
								.swiper-pagination-bullet{background: rgba(255,255,255,.4); width: 12px;height:3px;border-radius: 0;transition: all .3s ease;}
								.swiper-pagination-bullet.swiper-pagination-bullet-active{width: 20px;background: #1a3692;transition: all .3s ease;}
							}
						}
					}
					.swiper-prev{width: 28px;height:28px;background-image: url("~@/assets/image/18.png"); background-size: 9px;transform: translate(-10px,-100%)}
					.swiper-next{width: 28px;height:28px;background-image: url("~@/assets/image/19.png"); background-size: 9px;transform: translate(10px,-100%)}
				}
			}
		}
	}
}
</style>
